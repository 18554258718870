export default function footer() {

	const $ = jQuery;

	// Find all .menu-items that have a submenu
	const menuItems = document.querySelectorAll('.footer__menu .menu-item-has-children > a');

	if (!menuItems.length) {
		return;
	}

	// Loop through each menu item
	menuItems.forEach(function (menuItem) {

		// Add a click event listener to each menu item
		menuItem.addEventListener('click', function (e) {

			// Prevent the default link behavior
			e.preventDefault();

			// Get the submenu
			const subMenu = menuItem.nextElementSibling;

			// Toggle the submenu's visibility
			$(subMenu).slideToggle();

			//Add class to li
			$(menuItem).parent().toggleClass('is-open');
		});

	});
}