export default function restrictInput() {
    const ginputContainerPhone = document.querySelector('.ginput_container_phone');
  
    if (ginputContainerPhone) {
      ginputContainerPhone.addEventListener('input', function (event) {
        const inputValue = event.target.value;
        const numericInput = inputValue.replace(/[^0-9+]/g, ''); // Allowing only numbers and +
  
        if (inputValue !== numericInput) {
          event.target.value = numericInput;
        }
      });
    }
  }