export default function showJobs() {
    const $ = jQuery;
    const jobsItem = document.querySelectorAll('.jobs__item-title');

    jobsItem.forEach((item) => {
        item.addEventListener('click', () => {
            item.classList.toggle('active');
            const svg = item.querySelector('.jobs__svg');

            const newIcon = `<svg class="jobs__svg" xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.531 12.078h10.313v.844H2.53v-.844ZM14.156 12.078h7.313v.844h-8.157l.844-.844Z" fill="#171717"/></svg>`;
            
            const originalIcon = `<svg class="jobs__svg" xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21.969V3.03h.844V21.97H12Z" fill="#171717"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2.531 12.078h10.313v.844H2.53v-.844ZM14.156 12.078h7.313v.844h-8.157l.844-.844Z" fill="#171717"/></svg>`;
            
            // Toggle between new and original icon
            svg.innerHTML = item.classList.contains('active') ? newIcon : originalIcon;

            $(item).next().slideToggle();
        });
    });
}