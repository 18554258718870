import {archive} from '../components/archive';

const $ = jQuery;

let $currentSection;
let newsArchiveData = {
    action: 'newsArchiveAjax',
    posts_per_page: 9,
    s: '',
    terms: {},
    paged: 1,
};

export function newsArchive() {
    terms();
    getSearchData();
    resetFilters();
    pagination();
}

function terms() {
    $('.js-news-archive--options li').on('click', function () {
		const $this = $(this);
		const taxonomy = $this.closest('.js-news-archive--options').data('taxonomy');
		const termId = $this.data('term-id');
		$currentSection = $this.closest('section');
		newsArchiveData.terms = {};
		newsArchiveData.paged = 1;

		if ($currentSection.data('action')) {
            newsArchiveData.action = $currentSection.data('action');
        }

		if (taxonomy && termId) {
			newsArchiveData.terms[taxonomy] = parseInt(termId);
		}

		$('.js-news-archive--options li').removeClass('active');
		$this.addClass('active');
		archive($currentSection, newsArchiveData);
	});
}

function getSearchData() {
    let timer;
    $('#news-archive-search input').on('keyup', function () {
        const $this = $(this);
        const value = $(this).val();
        clearTimeout(timer);

        if (value.length > 2 || value.length === 0) {
            timer = setTimeout(function () {
                $currentSection = $this.closest('section');
                newsArchiveData.s = value;
                newsArchiveData.paged = 1;

                if ($currentSection.data('action')) {
					newsArchiveData.action = $currentSection.data('action');
				}
                archive($currentSection, newsArchiveData);
            }, 500);
        }
    });
}

function resetFilters() {
    $(document).on('click', '.js-archive--reset', function (e) {
        e.preventDefault();
        const $this = $(this);
        $currentSection = $this.closest('section');

        newsArchiveData = {
            action: 'newsArchiveAjax',
            posts_per_page: 9,
            s: '',
            terms: {},
            paged: 1,
        };

        if ($currentSection.data('action')) {
			newsArchiveData.action = $currentSection.data('action');
		}

        $('#news-archive-search input').val('');
        $('.js-news-archive--options li').removeClass('active');
        $('.js-news-archive--options li:first-child').addClass('active');
        archive($currentSection, newsArchiveData);
    });
}

function pagination() {
    $(document).on('click', '.page-numbers', function (e) {
        e.preventDefault();
        const $this = $(this);
        $currentSection = $this.closest('section');
        let pageNumber;
        let $currentElement = $this;

        if ($currentSection.data('action')) {
			newsArchiveData.action = $currentSection.data('action');
		}

        if ((!$this.hasClass('next')) && (!$this.hasClass('prev'))) {
            pageNumber = $this.text();
        } else {
            let activeNum = $this.closest('.pagination').find('.page-numbers.current');
            if ($this.hasClass('next')) {
                $currentElement = activeNum.next();
            }
            if ($this.hasClass('prev')) {
                $currentElement = activeNum.prev();
            }
            pageNumber = $currentElement.text();
        }

        pageNumber = parseInt(pageNumber);
        newsArchiveData.paged = pageNumber;

        archive($currentSection, newsArchiveData);
    });
}
