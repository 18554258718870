export default function megaMenu() {

	let menuItems = document.querySelectorAll('.js-mega-menu');
	let body = document.querySelector('body');

	menuItems.forEach(function (menuItem) {
		menuItem.addEventListener('click', function (event) {
			if (menuItem.classList.contains('active')) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();

			function closeMegaMenu() {
				menuItem.classList.remove('active');
				let activeContainer = document.querySelector('.mega-menu__container.show');
				if (activeContainer) {
					activeContainer.classList.remove('show');
				}
				body.classList.remove('mega-menu-open');
			}

			if (window.innerWidth < 1024) {
				// get distance of the clicked element from top of the screen
				let menuItemDistance = menuItem.getBoundingClientRect().top;
				let headerHeight = document.querySelector('.resp-menu__header').clientHeight;
				// Deduct height of the header from it
				menuItemDistance = menuItemDistance - headerHeight;

				let megaMenu = document.querySelector('.mega-menu');
				megaMenu.style.transform = `translateY(${menuItemDistance + 8}px)`;

				// Set height of .mega-menu__container to difference between menuItemDistance and window.innerHeight and 112px
				let container = document.querySelector('.mega-menu__container');
				let containerHeight = window.innerHeight - menuItemDistance - 150 - headerHeight;
				container.style.height = `${containerHeight}px`;
			}

			if (menuItem.classList.contains('active')) {
				closeMegaMenu();
				return;
			}

			// Hide mega menu if clicked outside of .mega-menu element
			document.addEventListener('click', function (event) {
				let isClickInside = menuItem.contains(event.target);
				if (!isClickInside) {
					closeMegaMenu();
				}
			});

			let activeItem = document.querySelector('.js-mega-menu.active');
			if (activeItem) {
				activeItem.classList.remove('active');
			}

			let activeContainer = document.querySelector('.mega-menu__container.show');
			if (activeContainer) {
				activeContainer.classList.remove('show');
			}

			// Toggle 'active' class for the clicked menu item
			menuItem.classList.toggle('active');

			body.classList.add('mega-menu-open');

			let containerClass = menuItem.classList.value.split(' ').filter(c => c.startsWith('js-') && c !== 'js-mega-menu')[0];

			// Find  .mega__menu__container with data attribute data-item="containerClass"
			let container = document.querySelector(`.mega-menu__container.${containerClass}`);

			// Toggle 'show' class for the container
			container.classList.add('show');

		});
	});
}
