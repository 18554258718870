export default function () {
	const headerNavigation = document.querySelector('.header__navigation');

	if(!headerNavigation) {
		return;
	}
	var $ = jQuery;
	var stickyHeader = function () {
		var header = $('#header');
		var authorNav = document.querySelector('.author-navigation');
		if (authorNav) {
			let authorNavDistance = authorNav.offsetTop;

			if ($(window).scrollTop() > authorNavDistance) {
				authorNav.classList.add('header--scrolled');
			} else {
				authorNav.classList.remove('header--scrolled');
			}
		}

		if ($(window).scrollTop() > 1) {
			header.addClass('header--scrolled');
		} else {
			header.removeClass('header--scrolled');
		}
	};

	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 1;
	var navbarHeight = $('.header--site').outerHeight();

	$(window).scroll(function (event) {
		didScroll = true;
	});

	setInterval(function () {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var st = $(document).scrollTop();

		// Make sure they scroll more than delta
		if (Math.abs(lastScrollTop - st) <= delta) return;

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight) {
			// Scroll Down
			$('.header--site').removeClass('nav-down').addClass('nav-up');
		} else {
			// Scroll Up
			if (st + $(window).height() < $(document).height()) {
				$('.header--site').removeClass('nav-up').addClass('nav-down');
			}
		}

		lastScrollTop = st;
	}

	window.addEventListener('load', stickyHeader);
	window.addEventListener('scroll', stickyHeader);
	window.addEventListener('resize', stickyHeader);
}