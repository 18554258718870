import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function hero() {
	const hero = document.querySelector('.hero .wrapper');

	if(!hero) return;

	// Only run if window size is larger than 1600px
	if(window.innerWidth < 1600) return;

	// Get width of the whole document and width of .hero.wrapper
	const docWidth = document.body.clientWidth;
	const heroWidth = hero.clientWidth;

	const remainingWidth = (docWidth - heroWidth) / 2;

	// Once I scroll, I want to move .hero__cover-left by half of the remaining width and ove .hero__cover-right by the other half, this is done in gsap

	gsap.to('.hero__cover--left', {
		x: remainingWidth,
		scrollTrigger: {
			trigger: '.hero',
			scrub: 2,
			start: 'top top',
			end: 'bottom bottom',
		}
	});

	gsap.to('.hero__cover--right', {
		x: -remainingWidth,
		scrollTrigger: {
			trigger: '.hero',
			scrub: 2,
			start: 'top top',
			end: 'bottom bottom',
		},
	});


}