import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const $ = jQuery;
const $casesCards = $('.js-case-map--card');
const $select = $('.js-cases-map--select');
const $country = $('.js-cases-map--select[data-taxonomy="country"]');
let cardsData = [];
let countryData = [];
let hoveredPolygonId = null;
let map = null;
let activeFilters = {
    countries: null,
    sector: null,
    facility: null,
    year: null,
};

/**
 * Cases map module
 */
export function casesMapModule() {
    const casesMap = document.getElementById('cases-map--map-box');
    if (casesMap) {
        getCountryData()
        getAllCaseCardsData();
        casesSelect();
        setHoverStateForCasesCards();
        showFilters();
        resetFilters();
    }

}

let isoCodeCoordinates;

/**
 * Init mapbox map
 */
export async function initCasesMap(countryData) {
	const $sidebar = $('.cases-map__sidebar');

	// Init map
	mapboxgl.accessToken = 'pk.eyJ1Ijoib21lbi1lc2FpYyIsImEiOiJjbG9paGIyeTgxaGowMmlxcGN2djk1dDE0In0.v5d-PfC-4VDb_32bSBvkfg';
	map = new mapboxgl.Map({
		container: 'cases-map--map-box',
		style: 'mapbox://styles/omen-esaic/clqm7obc400km01r5e3vh97oh',
		center: [31.2357, 30.04444],
		zoom: 3,
		pitch: 0,
		bearing: 0,
		interactive: true,
		doubleClickZoom: false,
		dragRotate: false,
	});

	// Use Mapbox Geocoding API to get coordinates for each ISO code
	isoCodeCoordinates = await Promise.all(
		countryData.map(async (country) => {
			const isoCode = country.code.toLowerCase();
			const response = await fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${isoCode}&country=${isoCode}&proximity=ip&types=country&access_token=${mapboxgl.accessToken}`);
			const data = await response.json();
			const coordinates = data.features.length > 0 ? data.features[0].geometry.coordinates : [0, 0]; // Default to [0, 0] if no coordinates found
			const wikiId = data.features.length > 0 ? data.features[0].properties.context.country.wikidata_id ?? null : null;
			const isoCodeUpper = country.code.toUpperCase();
			return { isoCode: isoCodeUpper, coordinates, wikiId, type: country.type };
		})
	);

	// Add country polygons to map
	map.on('load', () => {
		map.addSource('countries', {
			type: 'vector',
			url: 'mapbox://mapbox.country-boundaries-v1',
		});

		map.addLayer({
			id: 'country-fills',
			type: 'fill',
			source: 'countries',
			'source-layer': 'country_boundaries',
			layout: {},
			paint: {
				'fill-color': [
					'case',
					['boolean', ['feature-state', 'open'], false],
					'#D1E3FF', // Color when open for investment
					['boolean', ['feature-state', 'selected'], false],
					'#2C59C6', // Color when highlighted
					['boolean', ['feature-state', 'hover'], false],
					'#5B82D5', // Color when hovered
					['boolean', ['feature-state', 'highlighted'], false],
					'#96ACE3', // Color when highlighted
					'#f1f1f1', // Default color
					//'#DDE7F2', // Default color
				],
			},
		});

		if ($('.cases-map').hasClass('cases-map--no-filters')) {
			console.log('centerMapOnSelectedCountries');
			centerMapOnSelectedCountries(countryData);
		}

		map.addLayer({
			id: 'country-borders',
			type: 'line',
			source: 'countries',
			'source-layer': 'country_boundaries',
			layout: {},
			paint: {
				'line-color': '#B9B9B9',
				'line-width': 0.2,
			},
		});

		map.on('mousemove', 'country-fills', (e) => {
			if (e.features.length > 0) {
				const feature = e.features[0];
				const isoCode = feature.properties.iso_3166_1;

				// Check if the feature has the highlighted state set to true
				const isHighlighted = map.getFeatureState({
					source: 'countries',
					sourceLayer: 'country_boundaries',
					id: feature.id,
				}).highlighted;

				if (isHighlighted) {
					if (hoveredPolygonId !== null && hoveredPolygonId !== feature.id) {
						// Restore the highlighted state and remove the hover state for the previously hovered country
						map.setFeatureState(
							{
								source: 'countries',
								sourceLayer: 'country_boundaries',
								id: hoveredPolygonId,
							},
							{ highlighted: true, hover: false }
						);
					} else {
						// Set highlighted to false and hover to true for the currently hovered country
						map.setFeatureState(
							{
								source: 'countries',
								sourceLayer: 'country_boundaries',
								id: feature.id,
							},
							{ highlighted: false, hover: true }
						);
					}

					hoveredPolygonId = feature.id;
				}
			}
		});

		map.on('click', 'country-fills', (e) => {
			if (e.features.length > 0) {
				const feature = e.features[0];
				const isoCode = feature.properties.iso_3166_1;
				const $option = $select.find('option[value="' + isoCode + '"]');

				if ($option.length > 0) {
					$select.val(isoCode).trigger('change');
				}

				// Iterate over all features to reset the select state
				map.queryRenderedFeatures().forEach((f) => {
					if (f.id && f.id !== feature.id) {
						const featureState = map.getFeatureState({
							source: 'countries',
							sourceLayer: 'country_boundaries',
							id: f.id,
						});

						if (featureState.selected) {
							map.setFeatureState(
								{
									source: 'countries',
									sourceLayer: 'country_boundaries',
									id: f.id,
								},
								{ selected: false, highlighted: true, hover: false }
							);
						}
					}
				});

				// Check if the feature has the highlighted state set to true
				const featureState = map.getFeatureState({
					source: 'countries',
					sourceLayer: 'country_boundaries',
					id: feature.id,
				});

				if (featureState.hover) {
					// Set the selected state to true for the clicked feature
					map.setFeatureState(
						{
							source: 'countries',
							sourceLayer: 'country_boundaries',
							id: feature.id,
						},
						{ selected: true, highlighted: false, hover: false }
					);
				}
			}
		});
	});

	map.once('idle', () => {
		map.querySourceFeatures('countries', { sourceLayer: 'country_boundaries' }).forEach((feature) => {
			isoCodeCoordinates.forEach(({ wikiId, type }) => {
				if (feature.properties.wikidata_id === wikiId) {
					hoveredPolygonId = feature.id;

					// Determine the feature state based on the type
					const featureState = {
						highlighted: type !== 'investment', // false if type is "investment", otherwise true
						open: type === 'investment', // true if type is "investment"
					};

					map.setFeatureState(
						{
							source: 'countries',
							sourceLayer: 'country_boundaries',
							id: hoveredPolygonId,
						},
						featureState
					);
				}
			});
		});
	});
}

function centerMapOnSelectedCountries(countryData) {
	const bounds = new mapboxgl.LngLatBounds();

	isoCodeCoordinates.forEach(({ coordinates }) => {
		if (coordinates && coordinates.length === 2) {
			bounds.extend(coordinates);
		}
	});

	if (!bounds.isEmpty()) {
		map.fitBounds(bounds, {
			padding: 50, // Add padding around the bounds
			maxZoom: 4, // Optional: set a maximum zoom level
		});
	}
}


/**
 * Select2 for cases map
 */
function casesSelect() {
    $select.select2({
        width: '100%',
        allowClear: true,
    });

    $(document).on('select2:open', (e) => {
        const selectId = e.target.id;

        $('.select2-search__field[aria-controls=\'select2-' + selectId + '-results\']').each(function (key, value) {
            value.focus();
        });
    });

    $select.on('change', function (e) {
        const $this = $(this);
        const taxonomy = $this.data('taxonomy');

        if (taxonomy === 'country') {
            let country = $this.val();
            activeFilters.countries = country;

            if (country === null) {
                map.querySourceFeatures('countries', {sourceLayer: 'country_boundaries'}).forEach((feature) => {
                    const featureState = map.getFeatureState({
                        source: 'countries',
                        sourceLayer: 'country_boundaries',
                        id: feature.id,
                    });

                    if (featureState.selected) {
                        map.setFeatureState(
                            {source: 'countries', sourceLayer: 'country_boundaries', id: feature.id},
                            {selected: false, highlighted: true}
                        );
                    } else {
                        map.setFeatureState(
                            {source: 'countries', sourceLayer: 'country_boundaries', id: feature.id},
                            {hover: false}
                        );
                    }
                });
            }

            if (country) {
                country = country.toUpperCase();
                // center map on selected country
                // map.flyTo({
                //     center: isoCodeCoordinates.find(({isoCode}) => isoCode === country).coordinates,
                // });

                let wikiId = isoCodeCoordinates.find(({isoCode}) => isoCode === country).wikiId;
                let hoveredPolygonId = null;
                map.querySourceFeatures('countries', {sourceLayer: 'country_boundaries'}).forEach((feature) => {
                    if (feature.properties.wikidata_id === wikiId) {
                        hoveredPolygonId = feature.id;
                    }


                    map.setFeatureState(
                        {source: 'countries', sourceLayer: 'country_boundaries', id: feature.id},
                        {hover: false},
                    );
                });

                if (hoveredPolygonId !== null) {
                    map.setFeatureState(
                        {source: 'countries', sourceLayer: 'country_boundaries', id: hoveredPolygonId},
                        {selected: true},
                    );
                }
            }

            if (country === '' || country === null) {
                activeFilters.countries = null;
            }

            toggleCards(true);
        } else {
            let taxValue = $this.val();
            if (taxValue === '' || taxValue === null) {
                activeFilters[taxonomy] = null;
            }

            activeFilters[taxonomy] = taxValue;
            toggleCards();
        }
    });
}

function toggleCards(isCountrySelect = false) {
    let isAnyCardVisible = false;
    const markers = $('.cases-map__marker');
    if (!isCountrySelect) {
        markers.addClass('disabled');
    }

    cardsData.forEach(function (index, key) {
        let show = true;
        for (let filter in activeFilters) {
            if (activeFilters[filter] !== null) {
                let activeFilter = activeFilters[filter];
                if (filter !== 'countries') {
                    activeFilter = parseInt(activeFilter);
                }

                if (!index[filter].includes(activeFilter)) {
                    show = false;
                }
            }
        }

        const currentCard = $casesCards.filter('[data-post-id="' + index.id + '"]');
        let countryCode = currentCard.data('countries');
        if (countryCode.indexOf(',') === -1) {
            countryCode = [countryCode];
        } else {
            countryCode = countryCode.split(',');
        }

        if (show) {
            isAnyCardVisible = true;
            countryCode.forEach(function (value, key) {
                countryCode[key] = value.toUpperCase();
                const countryMarker = markers.filter('[data-iso-code="' + countryCode[key] + '"]');
                if (countryMarker.hasClass('disabled')) {
                    countryMarker.removeClass('disabled');
                }
            });

            currentCard.show();
        } else {
            currentCard.hide();
        }
    });

    let $notResult = $('.cases-map__no-results');
    if (!isAnyCardVisible) {
        $notResult.removeClass('disabled');
    } else {
        $notResult.addClass('disabled');

    }
}

/**
 * Get all data from cards
 */
function getAllCaseCardsData() {
    $casesCards.each(function (key, value) {
            const $this = $(this);

            cardsData.push({
                id: $this.data('post-id'),
                countries: caseData($this, 'countries'),
                sector: caseData($this, 'sector'),
                facility: caseData($this, 'facility'),
                year: caseData($this, 'year'),
            });
        },
    );
}

function caseData($card, type) {
    let data = $card.data(type);
    if (!data) {
        return [];
    }

    if (!isNaN(data)) {
        return [data];
    }

    if (data.indexOf(',') === -1) {
        return [data];
    }

    data = data.split(',');
    if (type === 'countries') {
        data.forEach(function (value, key) {
            data[key] = value.toUpperCase();
        });
    }

    return data;
}

/**
 * Set hover state for cases cards
 */
function setHoverStateForCasesCards() {
    $casesCards.on('mouseenter', function (e) {
        $('.cases-map__marker').removeClass('active');
        const $this = $(this);
        const countries = $this.data('countries');
        const countriesArray = countries.split(',');

        countriesArray.forEach(function (value, key) {
            countriesArray[key] = value.toUpperCase();
        });

        countriesArray.forEach(function (value, key) {
            if (countryData.includes(value)) {
                $('.cases-map__marker').filter('[data-iso-code="' + value + '"]').addClass('active');
            }
        });
    });
}

/**
 * Get all countries from select
 */
function getCountryData() {
	const countryData = [];
	$country.find('option').each(function () {
		const $this = $(this);
		const countryCode = $this.val();
		if (countryCode) {
			countryData.push({ code: countryCode, type: 'main' });
		}
	});

	const $casesMap = $('.cases-map');
	const investmentCountries = $casesMap.data('investment-countries');

	if (investmentCountries) {
		const investmentCountryArray = investmentCountries.split(',');
		investmentCountryArray.forEach((countryCode) => {
			if (countryCode && !countryData.find((item) => item.code === countryCode)) {
				countryData.push({ code: countryCode, type: 'investment' });
			}
		});
	}

	initCasesMap(countryData);
}



function showFilters() {
    $('.js-cases-map--toggler').on('click', function (e) {
        e.preventDefault();
        $('.cases-map__sidebar').toggleClass('active');
    });
}

function resetFilters() {
    let $resetBtn = $('.js-cases-map--reset');
    $resetBtn.on('click', function (e) {
        e.preventDefault();
        $select.val(null).trigger('change');
    });
}
