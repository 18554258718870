/**
 * Global imports
 */
import svg4everybody from 'svg4everybody';
import MobileMenu from './components/mobile-menu';
import restrictInput from './components/restrict-input';
import startSlideshow from './components/slideshow';
import showJobs from './components/show-jobs';
import footer from './components/footer';
import productSlider from './components/products-slider';
import relatedSlider from './components/related';
import team from './components/team';
import {casesMapModule} from './blocks/casesMap';
import partialsMap from './components/partialsMap';
import historyModule from './blocks/history';
import megaMenu from './global/megaMenu';
import Hero from './blocks/hero';
import SiteLine from './components/siteLine';
import header from './global/header';
import charts from './blocks/charts';
import {casesArchive} from './blocks/casesArchive';
import {newsArchive} from './blocks/newsArchive';
import {messagesArchive} from './blocks/messagesArchive';
import accordionEvent from './components/accordionEvent';

document.addEventListener('DOMContentLoaded', () => {
    svg4everybody();
    MobileMenu();
    restrictInput();
    startSlideshow();
    showJobs();
    footer();
    productSlider();
    relatedSlider();
    team();
    megaMenu();
    Hero();
    SiteLine();
    header();
    accordionEvent();



    if (document.querySelector('section.cases-map')) {
        casesMapModule();
    }

    if (document.querySelector('.js-partials--map')) {
        partialsMap();
    }

    if (document.querySelector('section.history')) {
        historyModule();
    }

    if (document.querySelector('section.charts')) {
        charts();
    }

    if (document.querySelector('.cases-archive')) {
        casesArchive();
    }

    if (document.querySelector('.news-archive')) {
		newsArchive();
	}

    if (document.querySelector('.messages-archive')) {
        messagesArchive();
    }
});
