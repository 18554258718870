import { archive } from '../components/archive';

const $ = jQuery;
const $select = $('.js-messages-archive--select select');

let $currentSection;
let casesArchiveData = {
	action: 'messagesArchiveAjax',
	posts_per_page: 6,
	s: '',
	terms: {},
};

export function messagesArchive() {
	messagesSelect();
	getSearchData();
	resetFilters();
	loadMore();
}

function messagesSelect() {
	$select.select2({
		width: '100%',
		allowClear: true,
	});

	$(document).on('select2:open', (e) => {
		const selectId = e.target.id;
		const $resultsContainer = $(".select2-search__field[aria-controls='select2-" + selectId + "-results']");

		if (selectId.includes('cases-archive')) {
			const $parentContainer = $resultsContainer.parents('.select2-container');

			if (!$parentContainer.hasClass('cases-archive__select-dropdown')) {
				$parentContainer.addClass('cases-archive__select-dropdown');
			}
		}

		$resultsContainer.each(function (key, value) {
			value.focus();
		});
	});

	// If we do restart we trigger ajax multiple times, to prevent this, i set a counter
	let numberOfChanges = 0;
	let timer;
	$select.on('change', function (e) {
		const $this = $(this);
		const taxonomy = $this.data('taxonomy');
		const termId = $this.val();
		$currentSection = $this.closest('section');

		if (taxonomy) {
			casesArchiveData.terms[taxonomy] = parseInt(termId);
		}

		if (termId === '' || termId === null) {
			delete casesArchiveData.terms[taxonomy];
		}

		if (numberOfChanges === 0) {
			archive($currentSection, casesArchiveData);
		} else {
			clearTimeout(timer);
			timer = setTimeout(function () {
				archive($currentSection, casesArchiveData);
				numberOfChanges = 0;
			}, 500);
		}

		numberOfChanges++;
	});
}

function getSearchData() {
	let timer;
	$('#cases-archive-search input').on('keyup', function () {
		const $this = $(this);
		const value = $(this).val();
		clearTimeout(timer);

		if (value.length > 2 || value.length === 0) {
			timer = setTimeout(function () {
				$currentSection = $this.closest('section');
				casesArchiveData.s = value;
				archive($currentSection, casesArchiveData);
			}, 500);
		}
	});
}

function resetFilters() {
	$(document).on('click', '.js-archive--reset', function (e) {
		e.preventDefault();
		const $this = $(this);
		$currentSection = $this.closest('section');

		casesArchiveData = {
			action: 'messagesArchiveAjax',
			posts_per_page: 6,
			s: '',
			terms: {},
		};

		$select.val(null).trigger('change');
		$('#cases-archive-search input').val('');
	});
}

function loadMore() {
	$(document).on('click', '.js-archive--load-more', function (e) {
		e.preventDefault();
		const $this = $(this);
		$currentSection = $this.closest('section');

		// get all posts from archive
		const $posts = $currentSection.find('.card-post');
		let postsIds = [];

		$posts.each(function (key, value) {
			postsIds.push($(value).data('post-id'));
		});

		casesArchiveData.offsetIds = postsIds;
		archive($currentSection, casesArchiveData, true);
		delete casesArchiveData.offsetIds;
	});
}
