import * as echarts from 'echarts';


export default function charts() {
    let chartDoms = document.querySelectorAll('.js-charts--chart-container');

    chartDoms.forEach(chartDom => {
        let chartDataContainer = chartDom.parentElement.querySelectorAll('.js-charts--data span');
        let chartData = [];
        chartDataContainer.forEach(data => {
            let chartItem = {
                name: data.dataset.name,
                value: data.dataset.value,
                color: data.dataset.color,
                percentage: data.dataset.percentage,
            };
            chartData.push(chartItem);
        });

        let chartType = chartDom.dataset.type;
        if (chartType === 'doughnut') {
            doughnutChart(chartDom, chartData);
        } else if (chartType === 'bar') {
            barChart(chartDom, chartData);
        }
    });
}

function barChart(chartDom, chartData) {
    let myChart = echarts.init(chartDom);
    let option;
    let defaultColor = '#2C59C6';
    let percentageYAxis = true;
    let maxYValue = 0;

    let preparedData = chartData.map(data => {
        const percentage = data.percentage ? data.percentage : '';
        if (maxYValue < parseFloat(data.value)) {
            maxYValue = Math.ceil(parseFloat(data.value) * 1.3 / 10) * 10;
        }

        if (!percentage) {
            percentageYAxis = false;
        }

        return {
            value: data.value,
            name: data.name,
            label: {
                show: true,
                position: 'top',
                fontFamily: 'neue-haas-grotesk-text',
                fontSize: 16,
                lineHeight: 16,
                color: data.color ? data.color : defaultColor,
                rich: {
                    value: {
                        fontSize: 16,
                        lineHeight: 16,
                        fontWeight: 700,
                        fontFamily: 'neue-haas-grotesk-text',
                        align: 'left',
                        padding: [0, 0, 8, 4],
                        width: 45,
                    },
                    name: {
                        fontSize: 11,
                        lineHeight: 11,
                        fontFamily: 'neue-haas-grotesk-text',
                        width: 45,
                    },
                },
                formatter: function (params) {
                    const valueWords = data.name.split(' ');
                    const formattedValue = valueWords.join('\n  ');
                    const percentage = data.percentage ? data.percentage : '';
                    let value = data.value;
                    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                    if (percentage) {
                        return `{value|${data.value}%}\n {name|${formattedValue}}`
                    }

                    return `{value|${value}}\n {name|${formattedValue}}`
                },
            },
            itemStyle: {
                color: data.color ? data.color : defaultColor,
            },
        };
    });

    let leftPadding = 0;
    let yAxisType = '{value}';
    if (percentageYAxis) {
        yAxisType = '{value}%';
        leftPadding = 15;

        if (maxYValue > 100) {
            maxYValue = 100;
        }
    }

    let valueLength = maxYValue.toString().length;
    for (let i = 0; i < valueLength; i++) {
        if (valueLength > 3) {
            leftPadding += 15;
        } else {
            leftPadding += 20;
        }
    }

    option = {
        grid: {
            top: 50,
            left: leftPadding,
            right: '2%',
            bottom: 10,
        },
        xAxis: {
            type: 'category',
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#DDDDDC',
                },
            },
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: maxYValue,
            // interval: yAxisInterval,
            axisLabel: {
                color: '#171717',
                formatter: yAxisType,
                fontSize: 16,
                lineHeight: 16,
                fontWeight: 700,
                fontFamily: 'neue-haas-grotesk-text',
            },
        },
        series: [
            {
                data: preparedData,
                type: 'bar',
                barWidth: 40,
                barGap: '30%',
            },
        ],
    };

    if (maxYValue <= 100) {
        option.yAxis.interval = 10;
    }

    if (maxYValue > 100 && maxYValue <= 200) {
        option.yAxis.interval = 20;
    }

    option && myChart.setOption(option);
}

function doughnutChart(chartDom, chartData) {
    let myChart = echarts.init(chartDom);
    let option;
    let defaultColor = '#2C59C6';

    let preparedData = chartData.map(data => {
        return {
            value: data.value,
            name: data.name,
            itemStyle: {
                color: data.color ? data.color : defaultColor,
            },
            label: {
                show: true,
                fontFamily: 'neue-haas-grotesk-text',
                fontSize: 16,
                lineHeight: 16,
                color: data.color ? data.color : defaultColor,
                rich: {
                    value: {
                        align: 'left',
                        fontSize: 16,
                        padding: [0, 0, 10, 0],
                    },
                    name: {
                        align: 'left',
                        fontSize: 16,
                    },
                },
                formatter: function (params) {
                    const percentage = data.percentage ? data.percentage : '';
                    if (percentage) {
                        return `{value|${data.value}%}\n${data.name}`
                    }
                    return `{value|${data.value}}\n${data.name}`
                },
            },
        };
    });

    option = {
        tooltip: {
            trigger: 'item',
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '70%'],
                itemStyle: {
                    borderRadius: 3,
                    borderColor: '#fff',
                    borderWidth: 4,
                },
                label: {
                    show: true,
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 18,
                        fontWeight: 'bold',
                    },
                },
                labelLine: {
                    show: false,
                },
                data: preparedData,
            },
        ],
    };

    option && myChart.setOption(option);
}
