export default function startSlideshow() {
    const slideshows = document.querySelectorAll('.slideshow');

    slideshows.forEach(slideshow => {
        const images = slideshow.querySelectorAll('.icons-text__image');
        
        if (images.length === 0) {
            return; // If no images found, skip creating
        }

        let currentIndex = 0;

        const showImage = index => {
            images.forEach(image => {
                image.style.opacity = '0';
                image.style.transition = 'opacity 0.5s ease-in-out'; 
            });

            images[index].style.opacity = '1';
        };

        const startSlideshow = () => {
            showImage(currentIndex);
            currentIndex = (currentIndex + 1) % images.length;
            setTimeout(startSlideshow, 5000);
        };

        startSlideshow();
    });
};
