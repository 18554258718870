import Swiper from 'swiper';
import {Autoplay, Navigation, Thumbs} from 'swiper/modules';
import * as echarts from 'echarts';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/autoplay';


let historyChartLine;
let HistoryData = {
    years: [],
    funding: [],
}

/**
 * History block
 */
export default function historyModule() {
    getChartItemsData();
    chart();
    sliders();
}

/**
 * Sliders for history block
 */
function sliders() {
    const yearsSlider = new Swiper('.js-history--years', {
        // loop: true,
        direction: "vertical",
        slidesPerView: 8,
        spaceBetween: 8,
        grabCursor: true,
        watchSlidesProgress: true,
    });

    const contentSlider = new Swiper('.js-history--content', {
        // loop: true,
        slidesPerView: 1,
        spaceBetween: 32,
        grabCursor: true,
        modules: [Navigation, Thumbs, Autoplay],
        thumbs: {
            swiper: yearsSlider,
        },
        navigation: {
            nextEl: '.history .js-history--content-next',
            prevEl: '.history .js-history--content-prev',
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        on: {
            slideChange: function () {
                let activeSlide = this.slides[this.activeIndex];
                let activeSlideYear = activeSlide.dataset.year;

                let chartData = {
                    funding: [],
                };

                // Get all items with smaller year than active slide
                let allItems = document.querySelectorAll('.js-history--item');
                allItems.forEach((item) => {
                    if (item.dataset.year <= activeSlideYear) {
                        chartData.funding.push(parseFloat(item.dataset.funding));
                    }
                });

                // Update chart
                let option = historyChartLine.getOption();
                option.series[0].data = chartData.funding.map((value, index) => ({
                    value,
                    label: {
                        show: true,
                        position: 'top',
                        color: index === this.activeIndex ? '#2C59C6' : '#171717', // Text color
                        fontSize: 16,
                        lineHeight: 16,
                        formatter: function (params) {
                            return '€' + value + 'M';
                        },
                    },
                    itemStyle: {
                        color: index === this.activeIndex ? '#2C59C6' : '#171717', // Point color
                    },
                }));
                historyChartLine.setOption(option);

            },
        },
    });
}

function chart() {
    let chartDom = document.querySelector('.js-history--chart');
    if (!chartDom) {
        return;
    }

    let customTextLabels = chartDom.dataset.labels;

    if (customTextLabels) {
        customTextLabels = customTextLabels.split('|');
    }

    historyChartLine = echarts.init(chartDom);
    let option;
    let funding = [HistoryData.funding[0]];
    let activeIndex = 0;


    option = {
        grid: {
            top: 40,
            left: 40,
            right: 30,
            bottom: 30,
        },
        xAxis: {
            type: 'category',
            data: HistoryData.years,
            axisLine: {
                show: true,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: true,
            },
            splitLine: {
                show: false,
            },
        },
        yAxis: {
            type: 'value',
            min: function (value) {
                return 0;
            },
            max: function (value) {
                let max = Math.max(...HistoryData.funding) * 1.2;
                return Math.ceil(max);
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: true,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#DDDDDC',
                },
            },
        },
        graphic: [
            {
                type: 'text',
                left: 10,
                top: 5,
                style: {
                    text: customTextLabels ? customTextLabels[0] : '',
                    fill: 'black',
                    font: 'big-caslon-fb',
                    fontSize: 16,
                },
            },
            {
                type: 'text',
                right: 10,
                bottom: 10,
                style: {
                    text: customTextLabels ? customTextLabels[1] : '',
                    fill: 'black',
                    font: 'big-caslon-fb',
                    fontSize: 16,
                },
            },
        ],
        series: [
            {
                data: funding.map((value, index) => ({
                    value,
                    label: {
                        show: true,
                        position: 'top',
                        color: index === activeIndex ? '#2C59C6' : '#171717', // Text color
                        fontSize: 16,
                        lineHeight: 16,
                        formatter: function (params) {
                            return '€' + value + 'M';
                        },
                    },
                    itemStyle: {
                        color: index === activeIndex ? '#2C59C6' : '#171717', // Point color
                    },
                })),
                type: 'line',
                symbol: 'circle',
                symbolSize: 6,
                lineStyle: {
                    color: '#DA2D44', // Line color
                },
                itemStyle: {
                    color: '#171717', // Point color
                },
                animation: true,
            },
        ],
    };

    option && historyChartLine.setOption(option);
    // Resize the chart when the window is resized
    window.addEventListener('resize', function () {
        historyChartLine.resize();
    });
}

function getChartItemsData() {
    let allItems = document.querySelectorAll('.js-history--item');
    allItems.forEach((item, index) => {
        HistoryData.years.push(parseInt(item.dataset.year));
        HistoryData.funding.push(parseFloat(item.dataset.funding));
    });
}
