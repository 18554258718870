import mapboxgl from 'mapbox-gl';


export default function () {
    // get all js-partials--map and set id to them
    const maps = document.querySelectorAll('.js-partials--map');
    maps.forEach((map, index) => {
        let containerId = `p-map-${index}`;
        map.setAttribute('id', containerId);
        let isoCodes = map.getAttribute('data-iso-code');
        isoCodes = isoCodes.split(',');
        isoCodes = isoCodes.map((isoCode) => {
            return isoCode.trim();
        });
        initMap(containerId, isoCodes);
    });
}

async function initMap(containerId, isoCodes = []) {
    mapboxgl.accessToken = 'pk.eyJ1Ijoib21lbi1lc2FpYyIsImEiOiJjbG9paGIyeTgxaGowMmlxcGN2djk1dDE0In0.v5d-PfC-4VDb_32bSBvkfg';
    let map = new mapboxgl.Map({
        container: containerId,
        style: 'mapbox://styles/omen-esaic/clqm7obc400km01r5e3vh97oh',
        center: [31.2357, 30.04444],
        zoom: 3.5,
        pitch: 0,
        bearing: 0,
        interactive: true,
        scrollZoom: false,
        dragPan: false,
        doubleClickZoom: false,
        dragRotate: false,
    });

    if (isoCodes.length > 0) {
        let fillColor = ['case'];
        isoCodes.forEach((isoCode) => {
            fillColor.push(['==', ['get', 'iso_3166_1'], isoCode], '#2C59C6');
        });
        fillColor.push('#F7F7F7');

        map.on('load', () => {
            map.addSource('countries', {
                'type': 'vector',
                'url': 'mapbox://mapbox.country-boundaries-v1',
            });

            map.addLayer({
                'id': 'country-fills',
                'type': 'fill',
                'source': 'countries',
                'source-layer': 'country_boundaries',
                'layout': {},
                'paint': {
                    'fill-color': fillColor,
                },
            });

            map.addLayer({
                'id': 'country-borders',
                'type': 'line',
                'source': 'countries',
                'source-layer': 'country_boundaries',
                'layout': {},
                'paint': {
                    'line-color': '#171717',
                    'line-width': 0.01,
                },
            });
        });

        if (isoCodes.length === 1) {
            let response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${isoCodes[0]}.json?access_token=${mapboxgl.accessToken}`);
            let data = await response.json();
            let coordinate = data.features.length > 0 ? data.features[0].center : [0, 0];

            map.setCenter(coordinate);
        } else {
            let coordinates = [];
            for (const isoCode of isoCodes) {
                let response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${isoCode}.json?access_token=${mapboxgl.accessToken}`);
                let data = await response.json();
                let coordinate = data.features.length > 0 ? data.features[0].center : [0, 0];
                coordinates.push(coordinate);
            }

            // Calculate the bounds manually
            let bounds = coordinates.reduce((bounds, coord) => {
                return bounds.extend(coord);
            }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

            // Adjust the zoom level to be slightly larger than the default
            let padding = 40; // You can adjust this value to control the padding around the bounds
            let options = {
                padding: padding,
            };

            map.fitBounds(bounds, options);
        }
    }
}
