export default function SiteLine() {

	const siteLineLeft = document.querySelector('.site-line--left');
	const siteLineRight = document.querySelector('.site-line--right');
	const mainWrapper = document.querySelector('.main .wrapper:not(.wrapper--hd)');

	if(!siteLineLeft || !siteLineRight || !mainWrapper) return;

	function moveSiteLines() {
		// Get width of document and .wrapper
		let docWidth = document.body.clientWidth;
		let wrapperWidth = mainWrapper.clientWidth;

		if(docWidth == wrapperWidth) return;

		let remainingWidth = (docWidth - wrapperWidth) / 2;

		let leftLine = document.querySelector('.site-line--left');
		let rightLine = document.querySelector('.site-line--right');

		// Add transform to both lines
		leftLine.style.transform = `translateX(${remainingWidth}px)`;
		rightLine.style.transform = `translateX(-${remainingWidth}px)`;
	}

	moveSiteLines();

	window.addEventListener('resize', () => {
		moveSiteLines();
	});

}