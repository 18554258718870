import Swiper from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

export default function productSlider() {
	let productsSlider = document.querySelector('.products-slider__links');

	if (!productsSlider) return;

    let slideContentHeight = productsSlider.querySelector('.c-card-product__page-info').offsetHeight;
   // Update css variable which is defined globally :root, variable name is --slide-content-height
    document.documentElement.style.setProperty('--slide-content-height', `${slideContentHeight}px`);

	const swiper = new Swiper('.products-slider__links', {
		slidesPerView: 1.2,
		spaceBetween: 16,
		grabCursor: true,
        breakpoints: {
            768: {
                slidesPerView: 1.4,
            },
            1024: {
                slidesPerView: 1.7,
            },
        },
	});
}